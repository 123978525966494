import { dayjs } from '/machinery/dayjs'

import styles from './MetaData.css'

export function MetaData({
  author,
  publicationDate,
  layoutClassName = undefined,
}) {
  const date = dayjs(publicationDate).locale('nl').format('D MMMM YYYY')
  return (
    <p className={cx(styles.component, layoutClassName)}>
      Geschreven op {date} door {author}.
    </p>
  )
}
